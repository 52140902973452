import React from "react";
import {
	Box,
	Grid,
	Button,
	TextField,

	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import orderStatus from "../../../../../constants/orderStatus";

const Filter = (props) => {
	const {
		initFilter,
		onChange,
		onReset
	} = props;
	const [filter, setFilter] = React.useState({ ...initFilter });
	const classes = useStyles();

	React.useEffect(() => {
		setFilter({ ...initFilter });
	}, [initFilter]);

	const handleChangeFilter = ({ target }) => {
		const { name, value } = target;

		let newFilter = { ...filter };
		newFilter[name] = value;
		setFilter(newFilter);
	}

	return (
		<Box className={classes.root}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={3}>
					<TextField
						value={filter.userName}
						fullWidth
						name="userName"
						label="ФИО мастера"
						size="small"
						placeholder="Введите"
						onChange={handleChangeFilter}
					/>
				</Grid>
				<Grid item xs={12} lg={3}>
					<TextField
						value={filter.userPhone}
						fullWidth
						name="userPhone"
						label="Номер мастера"
						size="small"
						placeholder="79000000000"
						onChange={handleChangeFilter}
					/>
				</Grid>

				<Grid item xs={12} lg="auto" marginLeft="auto">
					<Grid container spacing={2}>
						<Grid item xs={6} lg="auto">
							<Button
								variant="outlined"
								size="small"
								fullWidth
								sx={{height: 43}}
								onClick={onReset}
							>Сбросить</Button>
						</Grid>
						<Grid item  xs={6} lg="auto">
							<Button
								variant="contained"
								size="small"
								fullWidth
								sx={{height: 43}}
								onClick={onChange.bind(this, filter)}
							>Применить</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)
}

const useStyles = makeStyles(() => ({
	root: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",
		marginBottom: 16,

		"@media(max-width: 1199px)": {
			padding: 12,
		}
	}
}));

export default Filter