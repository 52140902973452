import React from "react";
import {
	Dialog,
	DialogContent
} from "@mui/material";
import ImageGallery from "react-image-gallery";
import {VideoPreview} from "../index";
import {isIos} from "../../common/Platform";

class DialogImagesVideos extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			files: [],

			activeIndex: 0,

			isOpen: false
		}
	}

	open = ({ files, activeIndex }) => {
		files = files.map((file) => {
			const isVideo = Boolean((file?.file?.type||"").includes("video"));
			if (isVideo) {
				return {
					original: URL.createObjectURL(file.file),
					thumbnail: URL.createObjectURL(file.file),
					renderItem: this.renderVideo.bind(this, file?.file),
					renderThumbInner: this.videoThumbInner.bind(this, file?.file)
				}
			}

			return {
				original: URL.createObjectURL(file.file),
				thumbnail: URL.createObjectURL(file.file),
			}
		})

		this.setState({
			files,
			activeIndex,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			isOpen: false
		})
	}

	videoThumbInner = (videoItem) => {
		return (
			<div style={{ maxWidth: 90, maxHeight: 60, overflow: "hidden" }}>
				<VideoPreview
					file={{
						file: videoItem
					}}
				/>
			</div>
		)
	}
	renderVideo = (item) => {
		return (
			<div>
				<video autoPlay={true} width="100%" controls>
					<source src={URL.createObjectURL(item)} />
				</video>
			</div>
		);
	}

	render () {
		const {
			files,
			activeIndex,
			isOpen
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
				onClose={this.close}
			>
				<DialogContent>
					<ImageGallery
						items={files}
						startIndex={activeIndex}
						showPlayButton={false}
						showIndex={true}
					/>
				</DialogContent>
			</Dialog>
		)
	}
}

export default DialogImagesVideos