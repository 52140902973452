import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	ApplicationInfo as ApplicationInfoComponent,
	WorkList as WorkListComponent,
	UserInfo as UserInfoComponent,
	DialogCreateWork as DialogCreateWorkComponent
} from "./components";
import {
	DialogImagesVideos
} from "../../../components";
import agent from "../../../agent/agent";
import {Notification, notificationTypes} from "../../../common/Notification";
import {clipboardWriteText} from "../../../common/Clipboard";
import optimizationImageVideo from "../../../common/OptimizationImageVideo";
import {getFileFromUrl} from "../../../common/Files";

class Application extends Component {
	constructor(props) {
		super(props);

		this.state = {
			application: {},

			applicationId: props?.match?.params?.id || null,

			isLoad: true,
			isFail: false,
			isShowBackdrop: false,
		};

		this.refDialogCreateWork = React.createRef();
		this.refDialogImagesVideos = React.createRef();
	}

	componentDidMount = async () => {
		await this.getApplication();
	}

	getApplication = async () => {
		const {applicationId} = this.state;

		let response = await agent.get(`/orders/mechanic/${applicationId}`).then((res) => {
			return res.data?.order || null
		}).catch((err) => {
			return null
		})
		if (response === null) {
			this.setState({
				isFail: true,
				isLoad: false
			})

			return
		}

		response.orderServices = [...response.orderServices].sort((a, b) => {
			if (a.createdAt > b.createdAt) {
				return 1
			}
			if (a.createdAt < b.createdAt) {
				return -1
			}
			return 0
		})
		response.orderServices = await Promise.all(([...response.orderServices]).map(async (service) => {
			service.pictures = await Promise.all((service.pictures || []).map(async (file) => {
				const uri = [process.env.REACT_APP_HOST_API, '/static/service/', file.name].join("");
				return {
					isNew: false,
					file: await getFileFromUrl(uri, file.name, file.mimetype)
				};
			}));

			return service
		}));

		this.setState({
			application: response,
			isLoad: false
		})
	}

	openDialogImagesVideos = (files, activeIndex) => {
		this.refDialogImagesVideos.current.open({
			files,
			activeIndex
		})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			application,

			isLoad,
			isFail,
			isShowBackdrop
		} = this.state;


		return (
			<>

				<VisibleContent visible={Boolean(!isLoad && !isFail)}>

					<Box mb={1} className={classes.user}>
						<ApplicationInfoComponent
							application={application}
						/>
					</Box>

					<Box mb={1} className={classes.user}>
						<UserInfoComponent
							client={application.client}
						/>
					</Box>

					<Box className={classes.root}>
						<WorkListComponent
							data={application.orderServices || []}
							disabledEdit={['CANCELED', 'CONFIRMED', 'PROCESS', 'COMPLETED'].includes(application.status)}
							onOpenDialogImagesVideos={this.openDialogImagesVideos}
						/>
					</Box>

				</VisibleContent>
				<VisibleContent visible={Boolean(isLoad)}>
					<Box className={classes.loading}>
						<img src={require("../../../assets/svg/loading/loading.svg").default}/>
					</Box>
				</VisibleContent>
				<VisibleContent visible={Boolean(isFail)}>
					<Box className={classes.error}>
						Заказ не найден
					</Box>
				</VisibleContent>


				<DialogCreateWorkComponent
					ref={this.refDialogCreateWork}
				/>
				<DialogImagesVideos
					ref={this.refDialogImagesVideos}
				/>
				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>
			</>
		);
	}
}

const VisibleContent = React.memo(({visible, children}) => {
	if (!visible) {
		return null
	}

	return children || null
})

const styles = {
	root: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 0,
			borderRadius: 0,
			border: "none",
			backgroundColor: "transparent",
		}
	},
	user: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 12
		}
	},

	loading: {
		height: 100,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",

		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"& img": {
			maxHeight: 80
		}
	},
	error: {
		fontSize: 20,
		lineHeight: "24px",
		textAlign: "center",
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",
	},
}
Application = withStyles(styles)(Application)

export default Application
