import React, {Component} from "react";
import {
	Box,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Filter as FilterComponent,
	TableApplications as TableApplicationsComponent,
	DialogCreateApplication as DialogCreateApplicationComponent,
	DialogRegistrationUser as DialogRegistrationUserComponent
} from "./components";
import agent from "../../../agent/agent";
import {Notification, notificationTypes} from "../../../common/Notification";
import queryString from "query-string";
import moment from "moment";
import {compose} from "recompose";
import {connect} from "react-redux";

const initFilter = {
	userName: "",
	userPhone: "",
	status: "",

	dateFrom: "",
	dateTo: "",
}

class Applications extends Component {
	constructor(props) {
		super(props);

		this.state = {
			applications: [],
			initApplications: [],

			filter: {...initFilter},
			pagination: {
				page: 1,
				totalPage: 1
			},

			isLoad: true,
			isShowBackdrop: false,
		};

		this.refDialogCreateApplication = React.createRef();
		this.refDialogRegistrationUser = React.createRef();
	}

	componentDidMount = async () => {
		await this.initFilter();
		await this.getApplications();
		this.scrollToSelectedApplication();
	}

	scrollToSelectedApplication = () => {
		const appId = this.props.global.chosenApplicationId;
		const el = document.querySelector(`#${appId}`);
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });
		}
	}

	initFilter = async () => {
		const locationSearch = this.props?.location?.search || "";
		let parseSearch = queryString.parse( locationSearch, {
			arrayFormat: "bracket"
		} );
		const page = parseSearch.page || 1;

		delete parseSearch.page;

		let filter = {
			...initFilter,
			...parseSearch
		};

		let pagination = {
			...this.state.pagination,
			page: page
		};

		await this.setState( {
			filter,
			pagination,
		} );
	}

	getApplications = async () => {
		this.setState({ isLoad: true })

		const filter = this.getFilters();

		let { orders, count } = await agent.get( `/orders/mechanic${ filter }&limit=20` )
			.then( (res) => res.data )
			.catch( (err) => {
				return []
			} );

		const pagination = { ...this.state.pagination };
		pagination.totalPage = Math.ceil( count / 20 ) || 1;

		this.setState({
			applications: orders,
			initApplications: orders,
			pagination,
			isLoad: false
		})
	}
	setApplications = async () => {
		const { filter, initApplications } = this.state;

		let list = [...initApplications]
			.filter((item) => {
				const fullUserName = [item?.client?.firstName, item?.client?.lastName].join(" ");
				const filterDateFrom = Boolean(filter.dateFrom) ? moment(filter.dateFrom).set({hour:0,minute:0,second:0,millisecond:0}) : "";
				const filterDateTo = Boolean(filter.dateTo) ? moment(filter.dateTo).set({hour:0,minute:0,second:0,millisecond:0}) : "";
				const createdAt = moment(item.createdAt).set({hour:0,minute:0,second:0,millisecond:0});

				const isStatus = Boolean(filter.status) ? item.status === filter.status : true;
				const isUserName = Boolean(filter.userName) ? fullUserName.includes(filter.userName) : true;
				const isUserPhone = Boolean(filter.userPhone) ? (item?.client?.phone || "").includes(filter.userPhone) : true;
				const isDateRange = Boolean(filterDateFrom && filterDateTo) ? Boolean(moment(filterDateFrom) <= createdAt && createdAt <= moment(filter.dateTo)) : true;

				return Boolean(isStatus && isUserName && isUserPhone && isDateRange)
			})

		this.setState({
			applications: list
		})
	}

	getFilters = () => {
		const filter = { ...this.state.filter };
		const pagination = { ...this.state.pagination };

		let string = [
			`page=${ pagination.page }`
		];

		Object.keys( filter ).map( (key) => {
			if (filter[key]) {
				let value = filter[key];

				string.push( `${ key }=${ value }` );
			}
		} );

		window.history.replaceState( null, null, `/applications?${ string.join( "&" ) }` );

		return `?${ string.join( "&" ) }`
	}

	changeFilter = async (filter) => {
		await this.setState({ filter });
		await this.setApplications();
	}

	changePagination = async (pagination) => {
		await this.setState( { pagination } );
		await this.getApplications();
	}

	resetFilter = async () => {
		await this.setState({
			filter: {...initFilter}
		})
		await this.setApplications();
	}

	createApplication = async (form = null) => {
		if (form === null) {
			this.refDialogCreateApplication.current.open({
				onSubmit: this.createApplication.bind(this)
			})

			return
		}

		this.setState({ isShowBackdrop: true })

		// Проверка на пользователя / регистрация
		const checkUserPhone = await agent.get(`/user/mechanic/client-by-phone?phone=7${form.clientPhoneNumber}`).then((res) => {
			return res.data?.client
		}).catch((err) => {
			return { error: true }
		})
		if (checkUserPhone.error) {
			this.setState({ isShowBackdrop: false })

			this.refDialogRegistrationUser.current.open({
				phone: `7${form.clientPhoneNumber}`,
				onSubmit: this.createApplication.bind(this, form)
			})

			return
		}

		// Создание заявки
		const createOrder = await agent.post(`/orders/mechanic`, {
			info: form.applicationName,
			clientId: checkUserPhone.id
		}).then((res) => {
			return res.data?.order
		}).catch((err) => {
			return { error: err.response }
		})
		if (createOrder.error) {
			this.setState({ isShowBackdrop: false })

			Notification({
				message: createOrder.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		// // Добавление параметров в зявку
		// const serviceOrder = await agent.post(`/orders/mechanic/${ createOrder.id }/services`, {
		// 	"description": form.applicationDescription,
		// 	"cost": Number.parseFloat(form.applicationPrice),
		// 	"estimates": (form.partsList || []).map((t) => {
		// 		return {
		// 			"description": t.name,
		// 			"partsCost": Number.parseFloat(t.price)
		// 		}
		// 	})
		// }).then((res) => {
		// 	return res.data
		// }).catch((err) => {
		// 	return { error: err.response }
		// })
		// if (serviceOrder.error) {
		// 	this.setState({ isShowBackdrop: false })
		//
		// 	Notification({
		// 		message: serviceOrder.error?.data?.details || "Ошибка сервера",
		// 		type: notificationTypes.error
		// 	})
		//
		// 	return
		// }

		await this.getApplications();
		this.refDialogCreateApplication.current.close();
		this.setState({ isShowBackdrop: false })
		Notification({
			message: "Заявка успешно создана",
			type: notificationTypes.success
		})

		this.props.history.push(`/applications/${ createOrder.id }`);
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			applications,
			filter,
			pagination,
			isLoad,
			isShowBackdrop
		} = this.state;

		return (
			<>

				<FilterComponent
					initFilter={filter}

					onChange={this.changeFilter}
					onReset={this.resetFilter}
				/>

				<Box className={classes.root}>

					<TableApplicationsComponent
						data={applications}
						isLoad={isLoad}
						pagination={pagination}
						onChangePagination={this.changePagination}
					/>


					<DialogCreateApplicationComponent
						ref={this.refDialogCreateApplication}
					/>
					<DialogRegistrationUserComponent
						ref={this.refDialogRegistrationUser}
					/>



					<Backdrop open={isShowBackdrop}>
						<CircularProgress/>
					</Backdrop>
				</Box>

			</>
		);
	}
}

const styles = {
	root: {
		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		border: "1px solid #F0F0F0",

		"@media(max-width: 1199px)": {
			padding: 0,
			borderRadius: 0,
			border: "none",
			backgroundColor: "transparent",
		}
	}
}
Applications = withStyles(styles)(Applications)

export default compose(
	connect(
		state => ({
			global: state.global
		})
	)
)(Applications);
